import BaseReport from '@/store/modules/BaseReport'

export default class MisServices extends BaseReport {
    constructor() {
        const reportUrl = '/backend/services/'
        super(reportUrl)

        this.namespaced = true

        this.state.misServices = undefined

        this.mutations.setItems = (state, misServices) => {
            state.misServices = misServices
        }

        this.actions.load = async (ctx, params) => {
            if (!params && ctx.state.misServices)
                return {
                    isSuccess: true,
                    result: ctx.state.misServices
                }
            
            const { isSuccess, result } = await BaseReport.makeRequest({
                method: 'get', 
                url: reportUrl,
                params
            })
            
            if (isSuccess && !params && !ctx.state.misServices)
                ctx.commit('setItems', result.results)
            
            return { isSuccess, result: result.results }
        }

        this.actions.getOne = async (ctx, id) => {
            return await BaseReport.makeRequest({
                method: 'get', 
                url: `${ reportUrl }${ id }/`,
            })
        }
    }
}
