import BaseReport from '@/store/modules/BaseReport'

export default class OpoConclusions extends BaseReport {
    constructor() {
        const reportUrl = '/backend/opo_conclusions/'
        super(reportUrl)

        this.actions.getOne = async function (ctx, id) {
            const url = `${reportUrl}${id}/`
            const method = 'get'
            return await BaseReport.makeRequest({ method, url })
        }
    }
}
