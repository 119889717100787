import BaseReport from '@/store/modules/BaseReport'
import { defaultColors } from '@/lib/themeConsts'
import { applyOpacity } from '@/lib/color'

export default {
    namespaced: true,
    state: {
        colors: undefined,
        loginBackground: undefined,
    },
    getters: {
        colors(state) {
            return state.colors ?? {}
        },
        loginBackground(state) {
            const loginBackgroundUrl = (process.env.VUE_APP_API_URL ?? window.location.origin) + state.loginBackground
            return state.loginBackground ? loginBackgroundUrl : null
        },
    },
    mutations: {
        update(state, { loginBackground, colors = defaultColors }) {
            // цвет один и тот же, но обе переменные используются в разных компонентах
            colors.error = colors.danger

            state.colors = colors
            state.loginBackground = loginBackground

            const cssVariables = {}
            // на основе полученных цветов сформируем стили 
            for (const [key, value] of Object.entries(colors)) {
                cssVariables[`--el-color-${ key }`] = applyOpacity(value)
                cssVariables[`--el-color-${ key }-dark-2`] = applyOpacity(value)
                cssVariables[`--el-color-${ key }-light-3`] = applyOpacity(value, 70)
                cssVariables[`--el-color-${ key }-light-5`] = applyOpacity(value, 50)
                cssVariables[`--el-color-${ key }-light-7`] = applyOpacity(value, 30)
                cssVariables[`--el-color-${ key }-light-8`] = applyOpacity(value, 20)
                cssVariables[`--el-color-${ key }-light-9`] = applyOpacity(value, 10)
            }
            // переопределим полученные стили в корневом элементе
            for (const [key, value] of Object.entries(cssVariables))
                document.documentElement.style.setProperty(key, value)
        },
    },
    actions: {
        async save(ctx, data) {
            const formData = new FormData()

            // добавим сериализованный обьект с цветами темы
            formData.append('vars', JSON.stringify(data.colors))

            const loginBackgroundFile = data.loginBackground?.[0]
            // если файл не передан, отправим пустой
            formData.append('logo_back_img', 
                loginBackgroundFile && 'raw' in loginBackgroundFile
                    ? loginBackgroundFile.raw
                    : new File([''], '')
            )

            const { isSuccess, result: theme } = await BaseReport.makeRequest({
                method: 'PUT',
                url: '/backend/settings/theme/',
                data: formData
            })

            if (isSuccess) {
                ctx.commit('update', {
                    colors: theme.vars,
                    loginBackground: theme.logo_back_img
                })    
            }
            return { isSuccess }
        },
    },
}
