const anonimRoutes = [
    {
        path: '/login/',
        name: 'login',
        component: () => import('@/views/LoginView'),
        meta: { title: 'Вход' },
    },
    {
        path: '/logout/',
        name: 'logout',
        component: () => import('@/views/LogoutView'),
        meta: { title: 'Выход' },
    },
    {
        path: '/admin/',
        name: 'admin',
        component: () => import('@/views/AdminRedirectView.vue'),
        meta: { title: 'Переход к администрированию' },
    },
]

const anonimViewsRoute = {
    path: '/',
    name: 'anonim',
    component: () => import('@/views/AnonimView'),
    children: anonimRoutes,
}

export default anonimViewsRoute
