<template>
    <el-breadcrumb separator="/" class="breadcrump">
        <el-breadcrumb-item
            v-for="crumb in crumbs"
            :key="crumb"
        >
            <router-link
                :to="{ path: crumb.link }"
                :class="{ link: crumb.link }"
            >
                {{ (crumb.name.call && crumb.name()) || crumb.name }}
            </router-link>
        </el-breadcrumb-item>
    </el-breadcrumb>
</template>

<script>
export default {
    name: 'BreadCrumb',
    props: {
        crumbs: Array,
    },
}
</script>

<style>
.breadcrump {
    padding: 15px 20px;
    margin-bottom: 15px;
    background-color: #f5f5f5;
    border-radius: 4px;
}
.el-breadcrumb__inner .link {
    font-weight: 400;
    color: var(--el-color-primary);
}
.el-breadcrumb__inner .link:hover {
    text-decoration: underline;
    filter: brightness(80%);
}
</style>

