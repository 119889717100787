import BaseReport from '@/store/modules/BaseReport'
import qs from 'qs'

export default class Visits extends BaseReport {
    constructor() {
        const reportUrl = '/backend/visits/'
        super(reportUrl)

        this.getters.getExcelUrl = () => (formData) => {
            const params = qs.stringify(this.getParams(formData), { arrayFormat: 'repeat' })
            return `${reportUrl}excel/?${params}`
        }

        this.actions.getByServices = async (ctx, params) => {
            const url = `${reportUrl}by_services/`
            const method = 'get'
            return await BaseReport.makeRequest({ method, url, params })
        }

        this.actions.getByMonth = async (ctx, params) => {
            const url = `${reportUrl}by_months/`
            const method = 'get'
            return await BaseReport.makeRequest({method, url, params })
        }

        this.actions.getOne = async function (ctx, id) {
            const url = `${reportUrl}${id}/`
            const method = 'get'
            return await BaseReport.makeRequest({method, url})
        }
    }
}
