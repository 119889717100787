export default class BaseView {
    constructor(namespace) {
        this.namespaced = true

        this.state = {
            tableData: [],
            formData: {},
            formErrors: {},
            paginator: {
                total: 0,
                pageSize: 50,
                page: 1,
            }
        }

        this.getters = {
            tableData: state => state.tableData,
            formErrors: state => state.formErrors,
            paginator: state => state.paginator,
        }

        this.actions = {
            load: async ctx => {
                // очищаем ошибки формы
                ctx.state.formErrors = {}
                
                // обьединяем данные формы и пагинатора
                const data = Object.assign({}, ctx.state.formData, {
                    pageSize: ctx.state.paginator.pageSize,
                    page: ctx.state.paginator.page
                })
                const response = await ctx.dispatch(`${namespace}/load`, data, { root: true })

                // сторы могут возвращать сразу результат или объект с информацией (isSuccess, count...)
                if (typeof response === 'object' && 'result' in response && 'isSuccess' in response) {
                    if (response.isSuccess) {
                        ctx.state.tableData = response.result.results
                        ctx.commit('setPaginator', {
                            total: response.result.count
                        })
                    } 
                    else {
                        // могут не прийти ошибки формы
                        ctx.state.formErrors = response.result?.errors || {}
                    }
                    return
                }
                this.items = response
            },
        }

        this.mutations = {
            setFormData: (state, formData) => {
                state.formData = { ...formData }
            },
            setPaginator: (state, paginator) => {
                state.paginator = Object.assign(
                    {}, state.paginator, paginator
                )
            },
        }
    }
}
