import BaseReport from '@/store/modules/BaseReport'

export default class ProfConclusions extends BaseReport {
    constructor() {
        const reportUrl = '/backend/prof_conclusions/'
        super(reportUrl)

        this.actions.getOne = async function (ctx, id) {
            const url = `${reportUrl}${id}/`
            const method = 'get'
            return await BaseReport.makeRequest({ method, url })
        }

        this.actions.delete = async function (ctx, id) {
            let url = `${reportUrl}${id}/`
            let method = 'delete'
            return await BaseReport.makeRequest({ method, url })
        }
    }
}
