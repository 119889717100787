export default {
    namespaced: true,
    state: {
        PROJECT_NAME: undefined,
        PROJECT_LOGO: undefined,
    },
    mutations: {
        setupSettings(state, settings) {
            state.PROJECT_NAME = settings.logo_name

            const apiUrl = process.env.VUE_APP_API_URL || ''
            state.PROJECT_LOGO = settings.logo_img ? apiUrl + settings.logo_img : undefined

            // console.log('setupSettings', {
            //     VUE_APP_API_URL: process.env.VUE_APP_API_URL,
            //     apiUrl,
            //     logo_img: settings.logo_img,
            //     PROJECT_LOGO: state.PROJECT_LOGO,
            // })

        },
    },
    actions: {
        async load(ctx) {
            const { data: settings } = await this.$axios.get('/backend/settings/')
            ctx.commit('setupSettings', settings)
            return settings
        },
    },
}
