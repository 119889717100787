import BaseReport from '@/store/modules/BaseReport'

export default class Contracts extends BaseReport {
    constructor() {
        const reportUrl = '/backend/orgs/contracts/'
        super(reportUrl)

        this.actions.getOne = async function (ctx, id) {
            const url = `${reportUrl}${id}/`
            const method = 'get'
            return await BaseReport.makeRequest({method, url})
        }
    }
}
