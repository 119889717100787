import BaseReport from '@/store/modules/BaseReport'

export default {
    namespaced: true,
    state: {
        lawItems: undefined,
    },
    getters: {},
    actions: {
        async load(ctx, term) {
            if (!term && ctx.state.lawItems)
                return { isSuccess: true, result: ctx.state.lawItems }

            const { isSuccess, result } = await BaseReport.makeRequest({
                url: `/backend/prof_law_items/`,
                params: { term, per_page: 400 },
            })

            if (isSuccess && !term && !ctx.state.lawItems)
                ctx.commit('setItems', result.results)

            return { isSuccess, result: result.results }
        },
        async getOne(ctx, id) {
            const url = `/backend/prof_law_items/${id}/`
            const method = 'get'
            return await BaseReport.makeRequest({ method, url })
        }
    },
    mutations: {
        setItems(state, lawItems) {
            state.lawItems = lawItems
        },
    },
}
