<template>
    <el-tooltip
        effect="light"
        placement="top" 
        v-if="value.length > maxLength"
        :content="value"
    >
        <span>
            {{ value.slice(0, maxLength - 3) + '...' }}
        </span>
    </el-tooltip>
    <span v-else> {{ value }} </span>
</template>

<script>
export default {
    name: 'TruncTooltip',
    props: {
        value: String,
        maxLength: Number,
    },
}
</script>

