import BaseReport from "@/store/modules/BaseReport";

export default class Users extends BaseReport {
    constructor() {
        super('/backend/users/')

        this.state.usersList = undefined

        this.getters.usersList = function (state) {
            return state.usersList
        }

        this.actions.loadUsers = async function (ctx, force) {
            // грузим список пользователей, только
            // либо если нас попросили об этом явно (force == true),
            // либо если список пользователей еще не загружался (usersList == undefined )
            if (!force && ctx.state.usersList !== undefined)
                return

            const response = await this.$axios.get('/backend/users/')
            ctx.commit('setUsers', response.data.results)
        }

        this.actions.getOne = async function (ctx, id) {
            const url = `/backend/users/${id}/`
            const method = 'get'
            return await BaseReport.makeRequest({method, url})
        }

        this.actions.save = async function (ctx, data) {
            let url, method
            if (data.id) {
                url = `/backend/users/${data.id}/`
                method = 'put'
            } else {
                url = `/backend/users/`
                method = 'post'
            }

            return await BaseReport.makeRequest({method, url, data})
        }

        this.actions.delete = async function (ctx, id) {
            let url = `/backend/users/${id}/`
            let method = 'delete'
            return await BaseReport.makeRequest({method, url})
        }


        this.mutations.setUsers = function (state, users) {
            state.usersList = users
        }
    }
}
