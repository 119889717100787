import BaseReport from '@/store/modules/BaseReport'

export default class DocxTemplates extends BaseReport {
    constructor() {
        const reportUrl = '/backend/portal/docx_templates/'
        super(reportUrl)

        this.actions.getOne = async function (ctx, id) {
            const url = `${reportUrl}${id}/`
            const method = 'get'
            return await BaseReport.makeRequest({ method, url })
        }

        this.actions.save = async function (ctx, data) {
            let url = reportUrl
            let method = 'post'
            if (data.id || data.has('id')) {
                url = `${ reportUrl }${ data.id || data.get('id') }/`
                method = 'patch'
            }
            return await BaseReport.makeRequest({ url, method, data })
        }

        this.actions.delete = async function (ctx, id) {
            let url = `${reportUrl}${id}/`
            let method = 'delete'
            return await BaseReport.makeRequest({ method, url })
        }
    }
}
