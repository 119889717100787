export default {
    namespaced: true,
    state: {},
    getters: {},
    actions: {
        async load() {
            const response = await this.$axios.get(`/backend/pay_methods/`)
            return response.data.results
        },
    },
    mutations: {},
}
