import formatDate from "@/store/plugins/formatDate"

const formatISODate = date => {
    // ожидаем дату в формате строки rus или Date. Возвращаем в формате ISO
    // дата не задана
    if (!date)
        return date
    // дата в формате строки rus
    if (typeof(date) === 'string' && /\d{2}\.\d{2}\.\d{4}/.test(date))
        return date.split('.').reverse().join('-')
    // дата в формате обьекта Date
    else {
        const pad = n => `${ Math.floor(Math.abs(n)) }`.padStart(2, '0')
        return date.getFullYear() +
            '-' + pad(date.getMonth() + 1) +
            '-' + pad(date.getDate())
    }
}

const getStartDateMonth = () => {
    const dateNow = new Date()
    return new Date(
        dateNow.getFullYear(),
        dateNow.getMonth(),
        1, 23, 59, 59
    )
}

const getEndDateMonth = () => {
    const dateNow = new Date()
    return new Date(
        dateNow.getFullYear(),
        dateNow.getMonth() + 1,
        0, 23, 59, 59
    )
}

export default {
    install: (app) => {
        app.config.globalProperties.$formatDate = formatDate
        app.config.globalProperties.$formatISODate = formatISODate
        app.config.globalProperties.$getStartDateMonth = getStartDateMonth
        app.config.globalProperties.$getEndDateMonth = getEndDateMonth
    },
}
