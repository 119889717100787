<template>
    <el-col 
        :span="state.span"
        :offset="state.offset"
        :push="state.push"
        :pull="state.pull"
        :tag="tag"
    >
        <slot />
    </el-col>
</template>

<script>
const breakpoints = {
    xxs: width => width < 480,
    xs: width => width >= 480 && width < 768,
    sm: width => width >= 768 && width < 992,
    md: width => width >= 992 && width < 1200,
    lg: width => width >= 1200 && width < 1920,
    xl: width => width >= 1920,
}

export default {
    name: 'ColApp',
    props: {
        span: { type: Number, default: 24 },
        offset: { type: Number, default: 0 },
        push: { type: Number, default: 0 },
        pull: { type: Number, default: 0 },
        xxs: [Number, Object],
        xs: [Number, Object],
        sm: [Number, Object],
        md: [Number, Object],
        lg: [Number, Object],
        xl: [Number, Object],
        tag: { type: String, default: 'div' }
    },
    data() {
        return {
            width: window.innerWidth,
        }
    },
    computed: {
        state() {
            const state = { span: this.span, offset: this.offset, push: this.push, pull: this.pull }
            if (typeof this[this.windowSize] === 'number')
                return Object.assign(state, { span: this[this.windowSize] })
            else
                return Object.assign(state, this[this.windowSize])
        },
        windowSize() {
            for (const [key, value] of Object.entries(breakpoints)) {
                if (value(this.width))
                    return key
            }
            return ''
        },
    },
    methods: {
        updateWidth() {
            this.width = window.innerWidth
        },
        addResizeObserver() {
            window.addEventListener('resize', this.updateWidth)
        },
        removeResizeObserver() {
            window.addEventListener('resize', this.updateWidth)
        },
    },
    created() {
        this.addResizeObserver()
    },
    unmounted() {
       this.removeResizeObserver()
    },
}
</script>
