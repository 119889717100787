export default function (date) {
    // ожидаем дату в формате строки ISO. Возвращаем в формате rus
    // дата не задана
    if (!date || isNaN(new Date(date)))
        return date

    // проверим, возможно date уже в нужно формате
    if (typeof(date) === 'string' && /\d{2}\.\d{2}\.\d{4}/.test(date))
        return date

    let localDate = new Date(date)
    return new Intl.DateTimeFormat('ru').format(localDate)
}