const defaultRoutes = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/home/IndexView'),
        meta: { title: 'Личный кабинет' },
    },
    {
        path: '/settings/',
        name: 'settings',
        component: () => import('@/views/settings/IndexView'),
        meta: { title: 'Настройки' },
    },
    {
        path: '/users/',
        name: 'users',
        component: () => import('@/views/settings/users/SearchView'),
        meta: { title: 'Пользователи', perm: 'core.view_user' },
    },
    {
        path: '/users/add/',
        name: 'addUser',
        component: () => import('@/views/settings/users/EditView'),
        meta: { title: 'Новый пользователь', perm: 'core.add_user' },
    },
    {
        path: '/users/:id(\\d+)+/',
        name: 'editUser',
        props: true,
        component: () => import('@/views/settings/users/EditView'),
        meta: { title: 'Редактировать пользователя', perm: 'core.change_user' },
    },
    {
        path: '/services/',
        name: 'services',
        component: () => import('@/views/settings/services/SearchView'),
        meta: { title: 'Услуги', perm: 'portal.view_service' },
    },
    {
        path: '/services/add/',
        name: 'addService',
        component: () => import('@/views/settings/services/EditView'),
        meta: { title: 'Новая услуга', perm: 'portal.add_service' },
    },
    {
        path: '/services/:id(\\d+)+/',
        name: 'editService',
        props: true,
        component: () => import('@/views/settings/services/EditView'),
        meta: { title: 'Редактировать услугу', perm: 'portal.change_service' },
    },
    {
        path: '/orgs/',
        name: 'orgs',
        component: () => import('@/views/settings/orgs/SearchView'),
        meta: { title: 'Организации', perm: 'core.view_organization' },
    },
    {
        path: '/orgs/edit/:id(\\d+)+/',
        name: 'editOrg',
        props: true,
        component: () => import('@/views/settings/orgs/EditView'),
        meta: { title: 'Редактировать организацию', perm: 'core.change_organization' },
    },
    {
        path: '/orgs/:id(\\d+)+/',
        name: 'org',
        props: true,
        component: () => import('@/views/settings/orgs/DetailView'),
        meta: { title: 'Информация по организации', perm: 'core.view_organization' },
    },
    {
        path: '/docx_templates/',
        name: 'docxTemplates',
        component: () => import('@/views/settings/docxtemplates/SearchView'),
        meta: { title: 'Шаблоны документов', perm: 'portal.view_docxtemplate' },
    },
    {
        path: '/docx_templates/add/',
        name: 'addDocxTemplate',
        component: () => import('@/views/settings/docxtemplates/EditView'),
        meta: { title: 'Новый шаблон документов', perm: 'portal.add_docxtemplate' },
    },
    {
        path: '/docx_templates/:id(\\d+)+/',
        name: 'editDocxTemplate',
        props: true,
        component: () => import('@/views/settings/docxtemplates/EditView'),
        meta: { title: 'Редактировать шаблон документов', perm: 'portal.change_docxtemplate' },
    },
    {
        path: '/theme/edit/',
        name: 'editTheme',
        component: () => import('@/views/settings/theme/EditView'),
        meta: { title: 'Редактировать тему', perm: '' },
    },
    {
        path: '/workers/',
        name: 'workers',
        component: () => import('@/views/workers/SearchView'),
        meta: { title: 'Сотрудники', perm: 'core.view_worker' },
    },
    {
        path: '/workers/:id(\\d+)+/',
        name: 'worker',
        props: true,
        component: () => import('@/views/workers/DetailView'),
        meta: { title: 'Информация по сотруднику', perm: 'core.view_worker' },
    },
    {
        path: '/prof_conclusions/',
        name: 'prof_conclusions',
        component: () => import('@/views/profconclusions/SearchView'),
        meta: { title: 'Заключения профпатолога', perm: 'core.view_profconclusion' },
    },
    {
        path: '/prof_conclusions/:id(\\d+)+/',
        name: 'prof_conclusion',
        props: true,
        component: () => import('@/views/profconclusions/DetailView'),
        meta: { title: 'Заключение профпатолога', perm: 'core.view_profconclusion' },
    },
    {
        path: '/opo_conclusions/',
        name: 'opo_conclusions',
        component: () => import('@/views/opoconclusions/SearchView'),
        meta: { title: 'Заключения ОПО', perm: 'core.view_opoconclusion' },
    },
    {
        path: '/opo_conclusions/:id(\\d+)+/',
        name: 'opo_conclusion',
        props: true,
        component: () => import('@/views/opoconclusions/DetailView'),
        meta: { title: 'Заключение ОПО', perm: 'core.view_opoconclusion' },
    },
    {
        path: '/certificates/',
        name: 'certificates',
        component: () => import('@/views/certificates/SearchView'),
        meta: { title: 'Справки', perm: 'core.view_certificates' },
    },
    {
        path: '/certificates/:id(\\d+)+/',
        name: 'certificate',
        props: true,
        component: () => import('@/views/certificates/DetailView'),
        meta: { title: 'Справка', perm: 'core.view_certificates' },
    },
    {
        path: '/org_documents/',
        name: 'org_documents',
        component: () => import('@/views/orgdocuments/SearchView'),
        meta: { title: 'Документы', perm: 'portal.view_orgdoc' },
    },
    {
        path: '/org_documents/:id(\\d+)+/',
        name: 'org_document',
        props: true,
        component: () => import('@/views/orgdocuments/EditView'),
        meta: { title: 'Документ', perm: 'portal.view_orgdoc' },
    },
    {
        path: '/org_documents/add/',
        name: 'addOrgDocument',
        component: () => import('@/views/orgdocuments/EditView'),
        meta: { title: 'Новый документ', perm: 'portal.add_orgdoc' },
    },
    {
        path: '/directions/',
        name: 'directions',
        component: () => import('@/views/directions/SearchView'),
        meta: { title: 'Направления', perm: 'portal.view_direction' },
    },
    {
        path: '/directions/add/',
        name: 'addDirection',
        component: () => import('@/views/directions/EditView'),
        meta: { title: 'Новое направление', perm: 'portal.add_direction' },
    },
    {
        path: '/directions/:id(\\d+)+/',
        name: 'editDirection',
        props: true,
        component: () => import('@/views/directions/EditView'),
        meta: { title: 'Редактировать направление', perm: 'portal.view_direction' },
    },
    {
        path: '/outs/',
        name: 'outs',
        component: () => import('@/views/outs/SearchView'),
        meta: { title: 'Выезда', perm: 'core.view_out' },
    },
    {
        path: '/outs/:id(\\d+)+/',
        name: 'out',
        props: true,
        component: () => import('@/views/outs/DetailView'),
        meta: { title: 'Выезд', perm: 'core.view_out' },
    },
    {
        path: '/cont_lists/',
        name: 'cont_lists',
        component: () => import('@/views/contlists/SearchView'),
        meta: { title: 'Поименные списки', perm: 'core.view_contlist' },
    },
    {
        path: '/cont_lists/:id(\\d+)+/',
        name: 'cont_list',
        props: true,
        component: () => import('@/views/contlists/DetailView'),
        meta: { title: 'Поименный список', perm: 'core.view_contlist' },
    },
    {
        path: '/contracts/',
        name: 'contracts',
        component: () => import('@/views/contracts/SearchView'),
        meta: { title: 'Договоры', perm: 'core.view_organizationcontract' },
    },
    {
        path: '/contracts/:id(\\d+)+/',
        name: 'contract',
        props: true,
        component: () => import('@/views/contracts/DetailView'),
        meta: { title: 'Информация по договору', perm: 'core.view_organizationcontract' },
    },
    {
        path: '/reports/',
        name: 'reports',
        component: () => import('@/views/reports/IndexView'),
        meta: { title: 'Отчеты' },
    },
    {
        path: '/reports/visits/',
        name: 'reportVisits',
        component: () => import('@/views/reports/visits/IndexView.vue'),
        meta: { title: 'Отчет по прошедшим', perm: 'core.view_report_visits' },
    },
    {
        path: '/reports/visits/services/',
        name: 'reportVisitsServices',
        component: () => import('@/views/reports/services/IndexView.vue'),
        meta: { title: 'Отчет детализация по услугам', perm: 'core.view_report_services' },
    },
    {
        path: '/reports/visits/:id(\\d+)+/',
        name: 'visit',
        props: true,
        component: () => import('@/views/visits/DetailView'),
        meta: { title: 'Посещение', perm: 'core.view_report_visits' },
    },
    {
        path: '/reset_password/',
        name: 'ResetPassword',
        component: () => import('@/views/PasswordChangeView'),
        meta: { title: 'Изменение пароля'}
    },
    {
        path: '/permission_deny/',
        name: 'PermissionDeny',
        component: () => import('@/views/errors/PermissionDeny.vue'),
        meta: { title: 'Недостаточно прав' },
    },
    {
        path: '/error/',
        name: 'error',
        component: () => import('@/views/errors/ServerError.vue'),
        meta: { title: 'Ошибка сервера' },
    },
    {
        path: '/:catchAll(.*)',
        component: () => import('@/views/errors/PageNotFound.vue'),
        meta: { title: 'Страница не найдена' },
    },
]

const defaultViewsRoute = {
    path: '/',
    name: 'default',
    component: () => import('@/views/DefaultView'),
    children: defaultRoutes,
}

export default defaultViewsRoute
