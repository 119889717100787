import BaseReport from '@/store/modules/BaseReport'

export default class Outs extends BaseReport {
    constructor() {
        const reportUrl = '/backend/outs/'
        super(reportUrl)

        this.actions.getOne = async function (ctx, id) {
            const url = `${reportUrl}${id}/`
            const method = 'get'
            return await BaseReport.makeRequest({method, url})
        }

        /**
         * предстоящие выезда
         */
        this.actions.getUpcoming = async () => {
            const today = new Date().toISOString().slice(0,10)
            return BaseReport.makeRequest({
                url: reportUrl,
                params: {date_from: today},
            })
        }
    }
}
