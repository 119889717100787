export default {
    state: {
        isLoading: false,
    },
    getters: {
        isLoading(state) {
            return state.isLoading
        },
    },
    mutations: {
        startLoading(state) {
            state.isLoading = true
        },
        stopLoading(state) {
            state.isLoading = false
        },
    },
}
