const convertHexToRGBA = (hexCode, opacity = 1) => {
    let hex = hexCode.replace('#', '')

    if (hex.length === 3)
        hex = `${ hex[0] }${ hex[0] }${ hex[1] }${ hex[1] }${ hex[2] }${ hex[2] }`
    
    const red = parseInt(hex.substring(0, 2), 16)
    const green = parseInt(hex.substring(2, 4), 16)
    const blue = parseInt(hex.substring(4, 6), 16)

    if (opacity > 1 && opacity <= 100)
        opacity = opacity / 100

    return `rgba(${ red },${ green },${ blue },${ opacity })`
}

const convertRGBAToHex = rgba => {
    rgba = rgba.match(
        /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    )
    const red = Math.round(rgba[1] * rgba[4] + 255 * (1 - rgba[4]))
    const blue = Math.round(rgba[2] * rgba[4] + 255 * (1 - rgba[4]))
    const green = Math.round(rgba[3] * rgba[4] + 255 * (1 - rgba[4]))

    return '#' +
        ('0' + red.toString(16)).slice(-2) +
        ('0' + blue.toString(16)).slice(-2) +
        ('0' + green.toString(16)).slice(-2)
}

const applyOpacity = (hexCode, opacity) =>
    convertRGBAToHex(convertHexToRGBA(hexCode, opacity))

export {
    convertHexToRGBA,
    convertRGBAToHex,
    applyOpacity,
}
