import { createApp } from 'vue'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import "../src/assets/styles.scss"
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import ru from 'element-plus/es/locale/lang/ru'
import 'dayjs/locale/ru'
import 'element-plus/theme-chalk/display.css'

// components
import BreadCrumb from './components/BreadCrumb.vue'
import TruncTooltip from './components/TruncTooltip.vue'
import ColApp from './components/ColApp.vue'

//plugins
import formatDate from './plugins/formatDate'
import openDocument from './plugins/openDocument'
import formatNum from './plugins/formatNum'
import forms from './plugins/forms'

import App from './App.vue'
import router from './router'
import store from './store'

const app = createApp(App)

for (const [key, component] of Object.entries(ElementPlusIconsVue))
    app.component(key, component)

app.use(store)
    .use(formatDate)
    .use(openDocument)
    .use(formatNum)
    .use(forms)
    .use(router)
    .use(ElementPlus, { locale: ru })
    .component('bread-crumb', BreadCrumb)
    .component('trunc-tooltip', TruncTooltip)
    .component('col-app', ColApp)
    .mount('#app')
