export default {
    namespaced: true,
    state: {},
    getters: {},
    actions: {
        async load(ctx, params) {
            let per_page = 100
            const response = await this.$axios.get(
                `/backend/managers/`,
                { params: { ...params, per_page } }
            )

            return response.data.results
        },
    },
    mutations: {},
}
