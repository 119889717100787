export default {
    install: (app) => {
        const requiredRules = {
            required: true,
            message: 'Пожалуйста, заполните это поле',
            trigger: 'blur',
        }


        app.config.globalProperties.$forms = {
            rules: {
                requiredRules,
            }
        }
    },
}
