import BaseReport from "./BaseReport"

export default class Orgs extends BaseReport {
    constructor() {
        const reportUrl = '/backend/orgs/'
        super(reportUrl)

        this.actions.getOne = async function (ctx, id) {
            const url = `${reportUrl}${id}/`
            const method = 'get'
            return await BaseReport.makeRequest({method, url})
        }

        this.actions.save = async (ctx, data) => {
            let url = reportUrl
            let method = 'post'
            if (data.id) {
                url = `${ reportUrl }${ data.id }/`
                method = 'put'
            }
            return await BaseReport.makeRequest({ url, method, data })
        }

        this.actions.delete = async (ctx, id) => {
            let url = `${ reportUrl }${ id }/`
            let method = 'delete'
            return await BaseReport.makeRequest({ method, url })
        }

        /**
         * принудительная синхронизация
         */
        this.actions.sync = async function (ctx, data) {
            const url = '/backend/sync/sync_orgs/'
            const method = 'post'
            return await BaseReport.makeRequest({ method, url, data })
        }
    }
}