import BaseReport from '@/store/modules/BaseReport'
import qs from 'qs'

export default class VisitsServices extends BaseReport {
    constructor() {
        const reportUrl = '/backend/portal/reports/services/'
        super(reportUrl)

        this.getters.getExcelUrl = () => (formData) => {
            const params = qs.stringify(this.getParams(formData), { arrayFormat: 'repeat' })
            return `${reportUrl}excel/?${params}`
        }
    }
}
