import BaseReport from "@/store/modules/BaseReport"

export default class Services extends BaseReport {
    constructor() {
        const reportUrl = '/backend/portal/services/'
        super(reportUrl)

        this.namespaced = true

        this.actions.load = async (ctx, params) => {
            return await BaseReport.makeRequest({
                method: 'get',
                url: reportUrl,
                params: Object.assign({}, {  per_page: 100 }, params)
            })
        }

        this.actions.getOne = async (ctx, id) => {
            const url = `${ reportUrl }${ id }/`
            const method = 'get'
            return await BaseReport.makeRequest({ method, url })
        }

        this.actions.save = async (ctx, data) => {
            let url = reportUrl
            let method = 'post'
            if (data.id) {
                url = `${ reportUrl }${ data.id }/`
                method = 'patch'
            }
            return await BaseReport.makeRequest({ url, method, data })
        }

        this.actions.delete = async (ctx, id) => {
            let url = `${ reportUrl }${ id }/`
            let method = 'delete'
            return await BaseReport.makeRequest({ method, url })
        }
    }
}
