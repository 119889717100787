import { createStore } from "vuex"
import axios from "axios"
import qs from "qs"

import settings from "@/store/modules/settings"
import user from "@/store/modules/user"
import loading from '@/store/modules/loading'
import theme from '@/store/modules/theme'
import managers from "@/store/modules/managers"
import Shops from "@/store/modules/shops"
import Contracts from "@/store/modules/contracts"
import payMethods from "@/store/modules/payMethods"
import profLawItems from "@/store/modules/profLawItems"
import opoActivityTypes from "@/store/modules/opoActivityTypes"
import Services from "@/store/modules/services"
import MisServices from "@/store/modules/misServices"
import medCenters from "@/store/modules/medCenters"
import Workers from "@/store/modules/workers"
import Directions from '@/store/modules/directions'
import Users from '@/store/modules/users'
import Outs from '@/store/modules/outs'
import ContLists from '@/store/modules/contLists'
import ProfConclusions from "@/store/modules/profConclusions"
import OpoConclusions from '@/store/modules/opoConclusions'
import Visits from "@/store/modules/visits"
import VisitsServices from "@/store/modules/visitsServices"
import Certificates from "@/store/modules/certificates"
import OrgDocuments from "@/store/modules/orgDocuments"
import router from "@/router"
import Orgs from "@/store/modules/orgs"
import Posts from "@/store/modules/posts"
import DocxTemplates from '@/store/modules/docxTemplates'

import DirectionsView from '@/store/modules/components/directionsView'
import OrgDocumentsView from '@/store/modules/components/orgDocumentsView'
import UsersView from '@/store/modules/components/usersView'
import ServicesView from '@/store/modules/components/servicesView'
import OrgsView from '@/store/modules/components/orgsView'
import DocxTemplatesView from "@/store/modules/components/docxTemplatesView"

let store = new createStore({
    modules: {
        settings,
        user,
        loading,
        theme,
        managers,
        orgs: new Orgs(),
        orgsView: new OrgsView(),
        shops: new Shops(),
        contracts: new Contracts(),
        posts: new Posts,
        payMethods,
        profLawItems,
        opoActivityTypes,
        services: new Services(),
        misServices: new MisServices(),
        servicesView: new ServicesView(),
        medCenters,
        workers: new Workers(),
        users: new Users(),
        usersView: new UsersView(),
        cont_lists: new ContLists(),
        directions: new Directions(),
        directionsView: new DirectionsView(),
        outs: new Outs(),
        profConclusions: new ProfConclusions(),
        opoConclusions: new OpoConclusions(),
        visits: new Visits(),
        visitsServices: new VisitsServices(),
        certificates: new Certificates(),
        orgDocuments: new OrgDocuments(),
        orgDocumentsView: new OrgDocumentsView(),
        docxTemplates: new DocxTemplates(),
        docxTemplatesView: new DocxTemplatesView(),
    },
})

store.$axios = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
    },
})

store.$axios.interceptors.request.use(function (config) {
    // если нет аутентификационного заголовка, то направляем на страницу входа
    if (config.url !== '/backend/auth/' && !config.headers.common.Authorization)
        router.push({name: 'login'})
    return config;
})

store.$axios.interceptors.response.use(
    function (response) {
        return response
    },
    function (error) {
        // токен в запросе не был предоставлен
        if (error.response.status === 401)
            router.push({name: 'login'})

        // токен не верный, или протух
        else if (
            error.response.status === 403 &&
            error.response.data.detail === 'Недопустимый токен.'
        )
            router.push({name: 'login'})
        return Promise.reject(error)
    }
)

export default store
