import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import routes from '@/router/routes'

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

router.beforeEach(function (to, from, next) {
    document.title = to.meta?.title || process.env.VUE_APP_PROJECT_NAME

    // Пользователь не сможет перейти на логинку если авторизован
    if (store.getters['user/isAuthenticated'] && to.name === 'login')
        next({ name: 'home'})

    // проверка на наличие права
    if (to.meta?.perm && !store.getters['user/hasPerm'](to.meta.perm))
        next({ name: 'PermissionDeny' })
    else next()
})

export default router
