<template>
    <el-container>
        <router-view />
    </el-container>
</template>

<script>
export default {
    name: 'App',
    async created() {
        this.$store.commit('user/restore')
        const settings = await this.$store.dispatch("settings/load")
        this.$store.commit('theme/update', {
            colors: settings?.theme?.vars,
            loginBackground: settings?.theme?.logo_back_img
        })
    },
}
</script>

