import BaseReport from '@/store/modules/BaseReport'

export default class OrgDocuments extends BaseReport {
    constructor() {
        const reportUrl = '/backend/portal/org_documents/'
        super(reportUrl)

        this.actions.getOne = async function (ctx, id) {
            const url = `${reportUrl}${id}/`
            const method = 'get'
            return await BaseReport.makeRequest({ method, url })
        }

        this.actions.save = async function (ctx, data) {
            let url, method
            if (data.id || data.has('id')) {
                url = `${reportUrl}${data.get('id')}/`
                method = 'patch'
            } else {
                url = reportUrl
                method = 'post'
            }

            return await BaseReport.makeRequest({ method, url, data })
        }

        this.actions.delete = async function (ctx, id) {
            let url = `${reportUrl}${id}/`
            let method = 'delete'
            return await BaseReport.makeRequest({ method, url })
        },
        
        this.actions.getList = async function (ctx, params) {
            const url = reportUrl
            const method = 'get'
            return await BaseReport.makeRequest({ method, url, params })
        }

        this.actions.getTypes = async function (ctx, params) {
            const url = '/backend/portal/org_doc_types/'
            const method = 'get'
            let result =  await BaseReport.makeRequest({ method, url, params })
            return result.result.results
        }

        this.actions.markAsViewed = async function (ctx, id) {
            let url = `${reportUrl}${id}/mark_as_viewed/`
            const method = 'patch'
            return await BaseReport.makeRequest({ method, url})
        }

        this.actions.unmarkAsViewed = async function (ctx, id) {
            let url = `${reportUrl}${id}/unmark_as_viewed/`
            const method = 'patch'
            return await BaseReport.makeRequest({ method, url})
        }
    }
}
