//права для настроек системы
const SETTINGS_PERMS = [
    'core.view_user',
]

export default {
    namespaced: true,
    state: {},
    getters: {
        isAuthenticated(state) {
            return Boolean(state.token)
        },
        isSuperUser(state) {
            return state.is_superuser
        },
        hasPerm: (state) => (permName) => {
            if (state.is_superuser)
                return true
            else if (state.permissions)
                return state.permissions.includes(permName)
        },
        hasSettingsPerm(state, getters) {
            for (let p of SETTINGS_PERMS)
                if (getters.hasPerm(p))
                    return true
            return false
        },
    },
    actions: {
        async auth(ctx, { username, password }) {
            let response
            try {
                response = await this.$axios.post('/backend/auth/', {
                    username: username,
                    password: password,
                })
            } catch (error) {
                response = error.response
            }
            const result = response.data
            const isSuccess = response.status === 200
            let description = ''
            if (isSuccess) {
                ctx.commit('update', result)
            } else if (response.status === 500) {
                description = 'Ошибка сервера'
            } else if (response.status === 400) {
                if (result.non_field_errors)
                    description = result.non_field_errors.join(', ')
                else 
                    description = 'Неверное имя пользователя или пароль'
            }
            return { isSuccess, description }
        },
        async resetPassword(ctx, { old_password, new_password }) {
            let response
            try {
                response = await this.$axios.post('/backend/auth/reset_password/', {
                    old_password,
                    new_password,
                })
            } catch (error) {
                response = error.response
            }
            const result = response.data
            const isSuccess = response.status === 200
            if (isSuccess) {
                ctx.commit('logout')
                return { isSuccess, result }
            }

            // ошибки валидации
            if (response.status === 400)
                return { isSuccess, result }

            // сервер сломался
            if (response.status === 500)
                return {
                    isSuccess,
                    result: {
                        errors: {
                            general: ['Ошибка сервера'],
                        },
                    },
                }

            return {
                isSuccess,
                result: {
                    errors: {
                        general: ['Неизвестная ошибка'],
                    },
                },
            }
            
        },
        //место, где можно прописать загрузку и очищение данных по пользователю (например, действующего менеджера...)
        loadMainInfo() {},
        clearMainInfo() {},
    },

    mutations: {
        update(state, user) {
            for (let [field, value] of Object.entries(user))
                state[field] = value

            //сохраняем пользователя в локальное хранилище, чтобы потом восстановить его
            localStorage.setItem('user', JSON.stringify(user))
            //Создаем дефолтные значения в axios
            if (user.token) {
                this.$axios.defaults.headers.common[
                    'Authorization'
                ] = `Token ${user.token}`
                // this.dispatch('user/loadMainInfo')
            } else {
                this.$axios.defaults.headers.common['Authorization'] = ''
                // this.dispatch('user/clearMainInfo')
            }
        },

        restore() {
            let userJson = localStorage.getItem('user')
            if (userJson) this.commit('user/update', JSON.parse(userJson))
        },
        
        logout(state) {
            // затрем все данные пользователя
            let user = {}
            for (let [field] of Object.entries(state)) user[field] = undefined

            this.commit('user/update', user)
        },
    },
}
