const defaultColors = {
    primary: '#409EFF',
    success: '#67C23A',
    warning: '#E6A23C',
    danger: '#F56C6C',
    error: '#F56C6C',
    info: '#909399',
}

const defaultLoginBackground = 'linear-gradient(90deg, #aea4e3, #d3ffe8)'

export {
    defaultColors,
    defaultLoginBackground,
}
