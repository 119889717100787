import BaseReport from '@/store/modules/BaseReport'
import formatDate from '@/store/plugins/formatDate'

export default class Directions extends BaseReport {
    constructor() {
        const reportUrl = '/backend/portal/directions/'
        super(reportUrl)

        this.actions.getOne = async function (ctx, id) {
            const url = `${reportUrl}${id}/`
            const method = 'get'
            return await BaseReport.makeRequest({ method, url })
        }

        this.actions.save = async function (ctx, data) {
            let url, method
            for (let key in data){
                if (key === 'birth')
                    data[key]=formatDate(data[key])
                if (key.includes('date'))
                    data[key]=formatDate(data[key])
            }
            if (data.id) {
                url = `${reportUrl}${data.id}/`
                method = 'put'
            } else {
                url = reportUrl
                method = 'post'
            }

            return await BaseReport.makeRequest({ method, url, data })
        }

        this.actions.delete = async function (ctx, id) {
            let url = `${reportUrl}${id}/`
            let method = 'delete'
            return await BaseReport.makeRequest({ method, url })
        }

        this.actions.getList = async function (ctx, params) {
            const url = reportUrl
            const method = 'get'
            return await BaseReport.makeRequest({ method, url, params })
        }

        this.actions.config = async function () {
            const url = '/backend/portal/directions/config/'
            return await BaseReport.makeRequest({ url })
        }
    }
}
