import BaseReport from '@/store/modules/BaseReport'

export default {
    namespaced: true,
    state: {
        activityTypes: undefined,
    },
    getters: {},
    actions: {
        async load(ctx, name) {
            if (!name && ctx.state.activityTypes)
                return { isSuccess: true, result: ctx.state.activityTypes }

            const { isSuccess, result } = await BaseReport.makeRequest({
                url: `/backend/opo_activity_types/`,
                params: { name, per_page: 100 },
            })

            if (isSuccess && !name && !ctx.state.activityTypes)
                ctx.commit('setItems', result.results)

            return { isSuccess, result: result.results }
        },
    },
    mutations: {
        setItems(state, activityTypes) {
            state.activityTypes = activityTypes
        },
    },
}
