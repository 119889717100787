const numberFormatter = new Intl.NumberFormat('ru')

export default {
    install: (app) => {
        app.config.globalProperties.$formatNum = (value) => {
            return numberFormatter.format(value)
        }
        app.config.globalProperties.$formatCost = (value) => {
            const div = Math.trunc(value)
            const mod = Math.trunc((value % div) * 100)
            let cost = `${numberFormatter.format(div)} руб.`
            if (mod)
                cost = cost.concat(` ${mod} коп.`)
            return cost
        }
    },
}