export default {
    namespaced: true,
    state: {},
    getters: {},
    actions: {
        async load(ctx, params = {}) {
            let response = await this.$axios.get(`/backend/med_centers/`, { params })
            return response.data.results
        },
    },
    mutations: {},
}